import GoogleMapReact from "google-map-react";
import { CSSProperties, PureComponent } from "react";
import { findDOMNode } from "react-dom";
import withCommonEvents from "../../../shared/hoc/with-common-events";

declare let window: any;

interface IMapCoords {
  lat?: number;
  lng?: number;
}

interface IGoogleMapsAPI {
  map: any;
  maps: any;
  ref: Element;
}

interface LocationPickerState {
  googleMapsAPI?: IGoogleMapsAPI;
}

interface LocationPickerProps {
  value?: { latitude?: number | null; longitude?: number | null };
  style: CSSProperties;
  latitude?: number;
  longitude?: number;
  mapCenterLatitude?: number;
  mapCenterLongitude?: number;
  onClick?: ({ latitude, longitude }) => void;
  onChange?: (value?: { latitude; longitude }) => void;
  id?: string;
}

class LocationPicker extends PureComponent<LocationPickerProps, LocationPickerState> {
  private markerRef: any;

  private latPropBeforeMapLoaded?: number | null;

  private lngPropBeforeMapLoaded?: number | null;

  constructor(props: LocationPickerProps) {
    super(props);
    this.state = {};
    this.latPropBeforeMapLoaded = props.value?.latitude ?? props.latitude;
    this.lngPropBeforeMapLoaded = props.value?.longitude ?? props.longitude;
  }

  componentDidMount() {
    this.setMapFocusId();
  }

  componentDidUpdate(): void {
    this.setMapFocusId();
    this.handlePlaceMarker();
  }

  setMapFocusId = () => {
    const node = findDOMNode(this) as Element;
    if (node) {
      const mapElement: any = node.getElementsByClassName("gm-style")[0];
      if (mapElement) {
        mapElement?.children[0]?.setAttribute("focusid", this.props.id);
      }
    }
  };

  handlePlaceMarker = () => {
    const { value } = this.props;
    if (this.state.googleMapsAPI) {
      if (this.markerRef) {
        if (typeof value?.latitude !== "number" && typeof value?.longitude !== "number") {
          this.markerRef.setMap(null);
          this.markerRef = null;
          return;
        }

        this.markerRef.setPosition({ lat: value?.latitude || 0, lng: value.longitude || 0 });
        return;
      }

      if (typeof value?.latitude !== "number" && typeof value?.longitude !== "number") {
        return;
      }

      const marker = new this.state.googleMapsAPI.maps.Marker({
        position: { lat: value?.latitude || 0, lng: value?.longitude || 0 },
        map: this.state.googleMapsAPI.map
      });
      this.markerRef = marker;
      return;
    }

    this.latPropBeforeMapLoaded = value?.latitude ?? this.latPropBeforeMapLoaded;
    this.lngPropBeforeMapLoaded = value?.longitude ?? this.lngPropBeforeMapLoaded;
  };

  setMapCenterToUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
        this.state.googleMapsAPI.map.setCenter({ lat: latitude, lng: longitude });
      });
    }
  };

  getStyleProp = (): CSSProperties => {
    let style: CSSProperties = {};
    if (this.props.style) {
      style = { ...this.props.style };
    }
    if (!style.height) {
      style.height = "400px";
    }
    if (!style.width) {
      style.width = "100%";
    }
    if ((style.display = "inline")) {
      style.display = "inline-flex";
    }

    // Must be relative
    style.position = "relative";

    return style;
  };

  renderDesignTime() {
    return <div className="googleMapsMock"></div>;
  }

  handleMapOnClick = ({ lat, lng }: IMapCoords) => {
    if (this.state.googleMapsAPI) {
      if (this.props.onClick) {
        this.props.onClick({ latitude: lat, longitude: lng });
      }
      if (this.props.onChange) {
        this.props.onChange({ latitude: lat, longitude: lng });
      }
    } else {
      this.latPropBeforeMapLoaded = lat;
      this.lngPropBeforeMapLoaded = lng;
    }
  };

  handleGoogleMapsApiLoaded = (googleMapsAPI) => {
    if (!googleMapsAPI) return;
    const latitude = this.getLatitude();
    const longitude = this.getLongitude();

    this.latPropBeforeMapLoaded = null;
    this.lngPropBeforeMapLoaded = null;

    this.setState({ googleMapsAPI }, () => {
      if (typeof latitude === "number" || typeof longitude === "number") {
        this.props.onChange({ latitude: latitude || 0, longitude: longitude || 0 });
        return;
      }

      this.setMapCenterToUserLocation();
    });
  };

  getLatitude = () => {
    if (!this.markerRef) {
      return this.latPropBeforeMapLoaded;
    }
    return this.markerRef.getPosition()?.lat();
  };

  getLongitude = () => {
    if (!this.markerRef) {
      return this.lngPropBeforeMapLoaded;
    }
    return this.markerRef.getPosition()?.lng();
  };

  renderRunTime() {
    return (
      <GoogleMapReact
        onGoogleApiLoaded={this.handleGoogleMapsApiLoaded}
        center={{ lat: this.props.value?.latitude || 0, lng: this.props.value?.longitude || 0 }}
        defaultZoom={11}
        onClick={this.handleMapOnClick}
        yesIWantToUseGoogleMapApiInternals
        layerTypes={["TrafficLayer", "TransitLayer"]}
        bootstrapURLKeys={{
          key: "AIzaSyCNHrogcR_C-7UqEASxR0-NGsNIrh6z7lE",
          libraries: ["places", "geometry"]
        }}
        options={{
          disableDoubleClickZoom: true
        }}
      />
    );
  }

  render() {
    const isDesignTime = window.kuika?.isDesignTime;

    return <div style={this.getStyleProp()}>{isDesignTime ? this.renderDesignTime() : this.renderRunTime()}</div>;
  }
}

const locationPicker = withCommonEvents(LocationPicker);

export { locationPicker as LocationPicker };
